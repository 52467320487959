













































































import Vue from "vue";

export default Vue.extend({
  name: "CancelationPolicy",
  mounted() {
    window.scrollTo(0, 0);
  },
});
